'use strict';

let lenisScroll = null;

window.addEventListener('load', () => {
    const isMobile = (() => {
        const userAgent = navigator.userAgentData ? navigator.userAgentData.brands.map(b => b.brand).join(" ") : navigator.userAgent;

        const devicePatterns = {
            Android: /Android/i,
            BlackBerry: /BlackBerry/i,
            iOS: /iPhone|iPad|iPod/i,
            Opera: /Opera Mini/i,
            Windows: /IEMobile/i
        };

        const checkDevice = device => devicePatterns[device].test(userAgent);

        return {
            Android: () => checkDevice('Android'),
            BlackBerry: () => checkDevice('BlackBerry'),
            iOS: () => checkDevice('iOS'),
            Opera: () => checkDevice('Opera'),
            Windows: () => checkDevice('Windows'),
            any: () => Object.keys(devicePatterns).some(checkDevice)
        };
    })();

    // Preloader Animation
    !(function () {
        setTimeout(() => {
            gsap.fromTo('.preloader__icon, .preloader__progress', {opacity: 1}, {opacity: 0});
            gsap.fromTo(
                '.preloader',
                {opacity: 1},
                {
                    opacity: 0,
                    duration: 0.8,
                    ease: 'easeInOut',
                    onComplete: () => {
                        document.querySelector('.preloader').remove();
                    },
                }
            );
        }, 500);
    })();

    // In-view Detect
    function initInViewDetect(delay = 500) {
        const onScroll = ($item) => {
            const rect = $item.getBoundingClientRect();
            const staticOffset = $item.dataset.inViewOffset;
            const inView = staticOffset ? rect.top - window.innerHeight <= staticOffset * -1 : rect.top - window.innerHeight <= -($item.offsetHeight / 4);

            if (inView && !$item.classList.contains('in-view')) {
                const delay = $item.closest('.header__dropdown') ? $item.dataset.delay / 3 : $item.dataset.delay;

                if ($item.classList.contains('split-text') || ($item.parentElement.classList.contains('child-split-text') && $item.tagName.match(/^H[1-3]$/))) {
                    const text = new SplitType($item, {
                        types: ($item.tagName.match(/^H[1-3]$/) ? 'lines, word, chars' : 'word, chars')
                    });
                    setTimeout(() => {
                        const $lines = text.elements[0].querySelectorAll('.line');

                        $lines.forEach(line => {
                            const $words = line.querySelectorAll('.word');
                            const $chars = line.querySelectorAll('.char');

                            gsap.set($words, {
                                opacity: 1
                            })
                            gsap.set($chars, {
                                scaleY: 0,
                                transformOrigin: "bottom"
                            })
                            gsap.to($chars, {
                                scaleY: 1,
                                stagger: .03,
                                duration: .7,
                                ease: "circ.out"
                            })
                        })
                    }, delay)
                }

                if ($item.classList.contains('opacity-mode')) {
                    const text = new SplitType($item, {types: 'word, chars'});
                    setTimeout(() => {
                        gsap.set(text.chars, {opacity: 0.3});
                        gsap.to(text.chars, {
                            stagger: 0.1,
                            opacity: 1,
                            scrollTrigger: {
                                trigger: $item,
                                start: "top 95%",
                                end: `bottom 50%+=${$item.offsetHeight / 2}`,
                                scrub: 1,
                            },
                        });
                    }, delay);
                }

                if ($item.classList.contains('in-view-detect')) {
                    $item.classList.replace('in-view-detect', 'in-view');
                } else {
                    $item.classList.add('in-view');
                }
            }
        };

        setTimeout(() => {
            const blocks = document.querySelectorAll('.in-view-detect, .in-view-detect-tag > *');
            const handleScroll = () => {
                blocks.forEach($item => onScroll($item));
            };

            window.addEventListener('scroll', handleScroll);
            handleScroll();
        }, delay);
    }

    initInViewDetect();

    // Logo Toggle
    (function () {
        const logotype = document.querySelector('.header__logo svg:nth-child(1)');
        const homeLink = document.querySelector('.header__logo svg:nth-child(2)');

        const tl = gsap.timeline({paused: true})
            .addLabel('start')
            .fromTo(logotype, {y: 0}, {y: -200, duration: 0.4}, 'start')
            .fromTo(homeLink,
                {scale: 0.5, autoAlpha: 0, transformOrigin: '30% 30%'},
                {scale: 1, autoAlpha: 1, duration: 0.25, ease: 'power1.out'}, 'start');

        ScrollTrigger.create({
            animation: tl,
            trigger: this,
            start: 'top top',
            end: 'top -200%',
            scrub: true,
        });
    })();

    // Hero Fade Out
    (function () {
        const heroContent = document.querySelector('.s-hero__content');

        if (!heroContent) return false;

        const nextSection = document.querySelector('.s-request--quote .s-request__gallery');
        const maxFadePercent = 200;

        function onScroll() {
            const heroRect = heroContent.getBoundingClientRect();
            const sectionRect = nextSection.getBoundingClientRect();

            const offsetHero = (heroRect.top + heroRect.height - window.innerHeight) * -1;
            const offsetSection = (sectionRect.top - window.innerHeight) * -1;

            const fadeDistance = heroContent.offsetHeight / 3;
            const passedDistance = offsetSection - offsetHero;
            let percent = Math.min(Math.max((passedDistance * 100) / fadeDistance, 0), maxFadePercent);

            heroContent.style.opacity = 1 - percent / maxFadePercent;
        }

        setTimeout(() => {
            onScroll();
            window.addEventListener('scroll', onScroll);
        }, 300);
    })();

    // Parallax Effect with GSAP and ScrollTrigger
    document.querySelectorAll('[data-parallax]').forEach($item => {
        const speed = $item.dataset.parallaxSpeed ? parseFloat($item.dataset.parallaxSpeed) : 1;
        const isLimited = $item.dataset.parallaxLimited ? 1.2 : 1;
        const isReverse = $item.dataset.parallaxReverse !== undefined;

        gsap.set($item, {
            y: () => {
                const offset = $item.offsetHeight / 5 * speed;
                return isReverse ? -offset : offset;
            },
            scale: isLimited
        });

        gsap.to($item, {
            y: () => {
                const offset = $item.offsetHeight / 20 * speed;
                return isReverse ? offset : -offset;
            },
            ease: "none",
            scrollTrigger: {
                trigger: $item,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            }
        });
    });

    // Delay Group Implementation
    !function () {
        const groups = document.querySelectorAll('.delay-group');

        function isHidden(el) {
            return window.getComputedStyle(el).display === 'none';
        }

        groups.forEach(group => {
            let index = Number(group.dataset.delay) || 0;

            const elements = group.querySelectorAll('.in-view-detect, .in-view-detect-tag > *, .hidden-detect');

            elements.forEach(el => {
                if (!isHidden(el)) {
                    index++;
                    const delay = index * 100;
                    el.style.setProperty('--delay', `${delay}ms`);
                    el.setAttribute('data-delay', delay);
                }
            });
        });
    }();

    // Lenis Scroll
    if (window.innerWidth > 1279 && !document.querySelector('.no-lenis')) {
        setTimeout(() => {
            lenisScroll = new Lenis({
                duration: 1.2,
                lerp: 0.1,
                smoothTouch: true,
                easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
            });

            function raf(time) {
                lenisScroll.raf(time);
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            if (isMobile.any()) {
                lenisScroll.destroy();
            }
        }, 200);
    }

    // menu
    !function () {
        const header = document.querySelector('.header');
        const menuBtn = document.querySelector('.header__menu');
        const menu = document.querySelector('.s-menu');
        const page = document.querySelector('.main');
        const shadow = document.querySelector('.shadow');

        function toggleMenu() {
            header.classList.toggle('is-menu-opened');
            menuBtn.classList.toggle('is-active');
            menu.classList.toggle('is-hide');
            menu.classList.toggle('is-show');
            page.classList.toggle('is-transform');
            shadow.classList.toggle('is-active');

            // bodyLockToggle();
        }

        menuBtn.addEventListener('click', toggleMenu)
        shadow.addEventListener('click', toggleMenu)
    }();

    // Showreel
    !function () {
        const $videoWrap = document.querySelectorAll('.s-showreel__video');

        if (window.innerWidth < 1280 || $videoWrap.length <= 0) return false;

        const tv = document.querySelector(".s-showreel__scene-inner");
        const $tvBox = document.querySelector('.s-showreel__tv-img img');
        const tvVideo = document.querySelectorAll(".s-showreel__video video");

        const tvWidth = tvVideo[0].offsetWidth;
        const tvHeight = tvVideo[0].offsetHeight;

        const scale = Math.max(window.innerWidth / tvWidth, window.innerHeight / tvHeight);

        gsap.to(tv, {
            scale: scale,
            yPercent: '15',
            duration: 1,
            ease: 'expoScale(1, 4, power3.in)',
            scrollTrigger: {
                trigger: ".s-showreel__inner",
                start: "top -20%",
                end: "bottom 150%",
                scrub: true,
                onLeave: () => {
                    tvVideo[1].style.opacity = '1';

                    setTimeout(() => {
                        tv.classList.add('is-fullscreen');
                        tvVideo[1].style.opacity = '0';
                    }, 500)
                },
                onEnterBack: () => {
                    tvVideo[1].style.opacity = '1';

                    setTimeout(() => {
                        tv.classList.remove('is-fullscreen');
                        tvVideo[1].style.opacity = '0';
                    }, 500)
                }
            }
        });
    }();

    // Showreel short
    let ready = false;
    !function () {
        const $videoWrap = document.querySelectorAll('.s-showreel__frame');

        if ($videoWrap.length > 0) {

            $videoWrap.forEach(($section) => {
                const $video = $section.querySelector('video');

                const src = $video.dataset.src;

                $video.setAttribute('src', src);

                $video.addEventListener('canplaythrough', (event) => {
                    $section.classList.add('canplay');
                    ready = true;
                    $video.pause();
                });

                const curWidth = 47;

                function onScroll() {
                    if ($section.getBoundingClientRect().top <= window.innerHeight / 2) {
                        const distance = window.innerHeight / 2;
                        const center = $section.getBoundingClientRect().top;
                        const number = distance - center;
                        let percent = Number((number * 100 / distance).toFixed(0))

                        if (percent >= 100) {
                            percent = 100;
                        }

                        if (ready) {
                            $video.play();
                        }

                        $section.classList.add('play');
                        $section.setAttribute('style', `--progress: ${percent / 100}`);

                    } else {
                        $section.classList.remove('play');
                    }
                }

                onScroll();

                document.addEventListener('scroll', onScroll)
            })
        }
    }();

    !function () {
        const cursor = new MouseFollower({
            skewing: 1.5,
            skewingMedia: .5
        })
    }();

    $('[data-fancybox]').fancybox({
        iframe: {
            preload: true
        },
        afterLoad: function () {
            document.querySelector('.fancybox-iframe').onload = () => {
                setTimeout(() => {
                    let videoSource = $('.fancybox-iframe').attr('src');
                    $('.fancybox-iframe').attr('src', `${videoSource}&autoplay=1`);
                    document.querySelector('.fancybox-iframe').onload = null;
                }, 1000)
            }
        }
    })


    // Case hover
    const $cases = document.querySelectorAll('.s-case__item');

    function setCasesHover() {
        let $cases = document.querySelectorAll('.s-case__item');

        $cases.forEach($case => {
            const $hover = $case.querySelector('.s-case__item-hover');

            $case.addEventListener('mouseenter', () => {
                $hover?.classList.remove('is-hide');
                $hover?.classList.add('is-show');
            });

            $case.addEventListener('mouseleave', () => {
                $hover?.classList.add('is-hide');
                $hover?.classList.remove('is-show');
            });
        });
    }

    if ($cases.length > 0) {
        setCasesHover();
    }


    // Theme Color Switcher
    !function () {
        const triggers = document.querySelectorAll('[data-theme]');
        const body = document.querySelector('.global-wrapper');

        if (!triggers.length) return;

        let currentTheme = 'black';
        let theme = triggers[0].dataset.theme;

        function updateThemeFromScroll() {
            triggers.forEach(trigger => {
                const rect = trigger.getBoundingClientRect();
                const themeOffsetPercent = parseFloat(trigger.dataset.themeOffset) || 0;



                const offset = themeOffsetPercent
                    ? rect.top + (trigger.offsetHeight * (themeOffsetPercent / 100))
                    : rect.top + trigger.offsetHeight - (window.innerHeight / 3);

                if (offset <= 0) {
                    theme = trigger.dataset.theme;
                }
            });
        }

        function changeTheme() {
            if (currentTheme !== theme) {

                const header = document.querySelector('.header-wrapper');
                let hero = document.querySelector('.s-page-hero');
                let color;
                let bg;
                let blobBg;

                if (theme !== 'custom') {
                    color = theme === 'white' ? '#1A1718' : '#FFB3CC';
                    bg = theme === 'white' ? '#fff' : '#1A1718';
                } else {
                    color = hero.dataset.themeColor;
                    bg = hero.dataset.themeBg;
                    blobBg = hero.dataset.themeBlobBg;

                    body.style.setProperty('--theme-blob-bg', blobBg);
                }

                if (hero && hero.dataset.headerTheme && theme === 'custom') {
                    const theme = hero.dataset.headerTheme;
                    let headerColor;
                    let headerBtnColor;
                    let headerBtnBg;
                    let headerBtnFilter;
                    let headerFilter;
                    let mixBlendMode;

                    switch (theme) {
                        case 'dark': {
                            headerColor = '#1A1718';
                            headerBtnColor = '#1A1718';
                            headerBtnBg = '#FFFFFF';
                            headerBtnFilter = '0';
                            headerFilter = '0';
                            mixBlendMode = 'initial';
                        }
                    }

                    header.style.setProperty('--header-color', headerColor);
                    header.style.setProperty('--header-btn-color', headerBtnColor);
                    header.style.setProperty('--header-btn-bg', headerBtnBg);
                    header.style.setProperty('--header-btn-filter', headerBtnFilter);
                    header.style.setProperty('--header-filter', headerFilter);
                    header.style.setProperty('--header-mix-blend-mode', mixBlendMode);
                } else {
                    header.removeAttribute('style');
                }

                body.style.setProperty('--theme-color', color);
                body.style.setProperty('--theme-bg', bg);
                body.className = 'global-wrapper';
                body.classList.add(`${theme}-theme`);
                currentTheme = theme;
            }
        }

        function debounce(func, delay) {
            let timeout;
            return function (...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), delay);
            };
        }

        const onScroll = debounce(() => {
            updateThemeFromScroll();
            changeTheme();
        }, 10);

        updateThemeFromScroll();
        changeTheme();

        window.addEventListener('scroll', onScroll);
    }();

    // Services Animation Implementation
    !function () {
        const rows = document.querySelectorAll('.s-services__item span');
        const number = document.querySelectorAll('.s-services__item');
        const progress = document.querySelectorAll('.s-services__progress span span');
        const progressHint = document.querySelector('.s-services__progress p');

        gsap.set(rows, {x: "120%", opacity: 0.2});
        gsap.to(rows, {
            x: "120%",
            duration: 0.9,
            stagger: 0.05,
            ease: "expo.out"
        });

        gsap.to(rows, {
            x: 0,
            stagger: 0.1,
            opacity: 1,
            scrollTrigger: {
                pinType: "fixed",
                trigger: '.s-services__list',
                start: "top 100%",
                end: "bottom 70%",
                scrub: 1,
            }
        });

        gsap.to(progress, {
            stagger: 0.05,
            width: '100%',
            scrollTrigger: {
                pinType: "fixed",
                trigger: '.s-services__list',
                start: "top 100%",
                end: "bottom 70%",
                scrub: 1,
            }
        });

        let ticking = false;

        function onScroll() {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    number.forEach(row => {
                        const rect = row.getBoundingClientRect();
                        const isVisible = rect.top <= window.innerHeight / 2 && (rect.top + rect.height + 9) >= window.innerHeight / 2;

                        if (isVisible) {
                            row.classList.add('is-active');
                            progressHint.innerHTML = row.dataset.hint;
                        } else {
                            row.classList.remove('is-active');
                        }
                    });
                    ticking = false;
                });
                ticking = true;
            }
        }

        onScroll();
        document.addEventListener('scroll', onScroll);
    }();

    // Carousel Slider Implementation
    !function () {
        const carousel = document.querySelector('.s-carousel');

        if (!carousel) return;

        const carouselBox = document.querySelector('.s-carousel__inner');

        gsap.set(carouselBox, {
            x: (window.innerWidth * 0.578704) / 4 * -1
        })

        gsap.to(carouselBox, {
            x: '0',
            scrollTrigger: {
                pinType: "fixed",
                trigger: '.s-carousel',
                start: "top 100%",
                end: "bottom -20%",
                scrub: 1,
            }
        })
    }();

    // About Animation Implementation
    !function () {
        const mediaMatch = window.matchMedia('(max-width: 1278.98px)');
        if (mediaMatch.matches) return;

        const rows = document.querySelectorAll('.s-about__text-col');

        rows.forEach((item, index) => {
            gsap.set(item, {
                x: index >= 2 ? '100%' : '-100%',
                opacity: 0
            });
        });

        gsap.to(rows, {
            x: 0,
            stagger: 0.05,
            duration: 0.5,
            opacity: 1,
            scrollTrigger: {
                pinType: "fixed",
                trigger: '.s-about__main',
                start: "top 90%",
                end: "bottom 110%",
                scrub: 1,
            }
        });
    }();

    // Shop Slider
    !(function () {
        let slider = document.querySelector('.s-shop-slider__swiper');

        if (!slider) return;

        let swiperSection = new Swiper(slider, {
            slidesPerView: 'auto',
            loop: true,
            spaceBetween: 0,
            initialSlide: 1,
            loopAdditionalSlides: 1,
            navigation: {
                nextEl: '.s-shop-slider__swiper .arrows__arrow--next',
                prevEl: '.s-shop-slider__swiper .arrows__arrow--prev',
            },
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                    initialSlide: 0
                },
                534: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                    initialSlide: 1
                },
            }
        });
    })();

    // Stories Slider
    !(function () {
        let slider = document.querySelector('.s-testimonials__slider');

        if (!slider) return;

        let swiperSection = new Swiper(slider, {
            slidesPerView: 'auto',
            loop: true,
            spaceBetween: 20,
            scrollbar: {
                el: '.s-testimonials .swiper-scrollbar',
                draggable: true,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            breakpoints: {
                992: {
                    spaceBetween: 84
                },
            }
        });
    })();

    // Footer Logo Animation
    !(function () {
        const footerLogoPaths = document.querySelectorAll('.footer__big-logo svg g path');
        const STAGGER_INCREMENT = 50;
        const STAGGER_LIMIT = 150;

        let stagger = 0;

        footerLogoPaths.forEach((path) => {
            const offset = stagger;
            stagger = (stagger + STAGGER_INCREMENT) % STAGGER_LIMIT;

            gsap.set(path, {
                y: -offset
            });

            gsap.to(path, {
                y: 0,
                autoAlpha: 1,
                duration: 0.5,
                stagger: 0.1,
                ease: "expo.out",
                scrollTrigger: {
                    pinType: "fixed",
                    trigger: '.footer__big-logo',
                    start: "top 95%",
                    end: "bottom 75%",
                    scrub: 1,
                }
            });
        });
    })();

    // Footer Reveal on Scroll
    !(function () {
        const footer = document.querySelector('.footer__bottom');
        const footerInner = document.querySelector('.footer__bottom .footer__inner');

        function onScroll() {
            const delta = Math.max(0, (footer.getBoundingClientRect().top - window.innerHeight) * -1);
            const distance = footer.offsetHeight;
            const percent = Math.min(50, 50 - (delta * 100 / distance) / 2);

            footerInner.style.transform = `translateY(-${percent}%)`;
        }

        if (!isMobile.any() && window.innerHeight >= footer.offsetHeight) {
            document.addEventListener('scroll', onScroll);
        } else {
            footerInner.style.transform = `translateY(0%)`;
        }
    })();

    // Footer accord
    !function () {
        const toggle = document.querySelectorAll('.footer__nav-accord-toggle');
        const open = document.querySelector('.footer__nav-accord-head');
        const content = document.querySelector('.footer__nav-accord-content');

        toggle.forEach(btn => {
            btn.addEventListener('click', () => {
                toggleAccord(open, content, 'is-hide');
            })
        })
    }();

    // Text shuffle
    function shuffle(input) {
        const a = typeof input === "string" ? input.split("") : input;
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a.join("");
    }

    function shuffleElementTexts(item, shuffleFn) {
        if ((!item.children.length || item.dataset.allowShuffle !== undefined) && item.innerText) {
            const words = item.innerText.replace("\n", " \n ").split(" ");
            item.innerText = words.map(word => {
                const chars = [...word];
                return shuffleFn(chars);
            }).join(" ");
        }
    }

    const elements = document.querySelectorAll(".btn, .primary-btn, .btn, .header__menu, .text-shuffle");

    function initShuffle(shuffleElements) {
        if (shuffleElements.length) {
            shuffleElements.forEach(el => {
                const textElements = el.querySelectorAll('[data-text]');

                textElements.forEach(item => {
                    item.setAttribute("aria-label", item.innerText);
                });

                el.addEventListener("mouseenter", (e) => {
                    if (window.innerWidth >= 1024) {
                        const $el = el.querySelectorAll('[data-text]');
                        const shufflePerSecond = 4;
                        const duration = 0.25;
                        const shuffleDelay = duration / shufflePerSecond;

                        $el.forEach(item => {
                            const tw = gsap.timeline({
                                onComplete: () => {
                                    item.innerText = item.getAttribute("aria-label");
                                },
                            });

                            for (let i = 0; i < shufflePerSecond; i++) {
                                tw.add(() => shuffleElementTexts(item, shuffle), shuffleDelay * i);
                            }
                        });
                    }
                });

                el.addEventListener("mouseleave", () => {
                    textElements.forEach(item => {
                        item.innerText = item.getAttribute("aria-label");
                    });
                });
            });
        }
    }

    initShuffle(elements)

    !function () {
        const modal = document.querySelector(".s-modal");
        const modalClose = document.querySelector(".s-modal__close");
        const showModalAfterScroll = window.innerHeight;
        let scrolledDown = false;
        let timerTriggered = false;
        let isScrollingDown = true;
        let lastScrollTop = window.scrollY;

        function setCookie(name, value, days) {
            const d = new Date();
            d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
            document.cookie = name + "=" + value + ";expires=" + d.toUTCString() + ";path=/";
        }

        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        }

        function showModal() {
            if (!getCookie("modalClosed")) {
                modal.classList.add('is-show');
            }
        }

        function closeModal() {
            modal.classList.remove('is-show');
            setCookie("modalClosed", "true", 1);
        }

        // Show modal after 10 seconds
        setTimeout(() => {
            timerTriggered = true;
            if (scrolledDown) {
                showModal();
            }
        }, 10000);

        window.addEventListener("scroll", () => {
            const st = window.scrollY;
            isScrollingDown = st > lastScrollTop;
            lastScrollTop = st <= 0 ? 0 : st;

            if (window.scrollY > showModalAfterScroll) {
                scrolledDown = true;
                if (timerTriggered) {
                    showModal();
                }

                if (isScrollingDown) {
                    modal.classList.add('is-active');
                } else {
                    modal.classList.remove('is-active');
                }
            }
        });

        modalClose.addEventListener("click", closeModal);
    }();

    // benefits aside
    !function () {
        const images = document.querySelectorAll('.s-benefits__aside-content img');
        const sections = document.querySelectorAll('.s-benefits__item');

        if (images.length <= 0 || sections.length <= 0) return;

        let ticking = false;
        let currentIndex = 0;
        let interval = null;

        function onScroll() {
            if (!ticking && window.innerWidth > 767) {
                window.requestAnimationFrame(() => {
                    const centerPoint = window.innerHeight * 0.5;

                    sections.forEach((item, index) => {
                        const rect = item.getBoundingClientRect();
                        const isCentered = rect.top <= centerPoint && rect.bottom >= centerPoint;

                        if (isCentered) {
                            images.forEach(img => img.classList.remove('is-active'));
                            images[index].classList.add('is-active');
                        }
                    });

                    ticking = false;
                });

                ticking = true;
            }
        }

        function startAutoSwitch() {
            interval = setInterval(() => {
                images.forEach(el => el.classList.remove('is-active'));
                images[currentIndex].classList.add('is-active');
                currentIndex = (currentIndex + 1) % images.length;
            }, 1500);
        }

        function stopAutoSwitch() {
            clearInterval(interval);
            interval = null;
        }

        function onResize() {
            if (window.innerWidth > 767) {
                stopAutoSwitch();
            } else if (!interval) {
                startAutoSwitch();
            }
        }

        document.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);

        onScroll();
        onResize();
    }();


    // benefits accord
    !function () {
        const benefits = document.querySelectorAll('.s-benefits__item');

        benefits.forEach(item => {
            const toggle = item.querySelector('.s-benefits__item-head');
            const content = item.querySelector('.s-benefits__item-content');

            toggle.addEventListener('click', () => {
                if (window.innerWidth < 768) {
                    toggleAccord(toggle, content);
                }
            })
        })
    }();

    // set height to grid
    !function () {
        const gridItems = document.querySelectorAll('.s-collaboration__item');
        const linesItems = document.querySelectorAll('.s-collaboration__lines span');

        if (gridItems.length <= 0 || linesItems.length <= 0) return false;

        gridItems.forEach((gridItem, index) => {
            const gridHeight = gridItem.getBoundingClientRect().height;
            linesItems[index].style.height = `${gridHeight}px`;
        });
    }();

    // orbit animation
    !function () {
        const container = document.querySelector('.s-who__orbit');
        const textItems = document.querySelectorAll('.s-who__item');

        if (!container || textItems.length <= 0) return false;

        const totalItems = textItems.length;

        function setEllipseDimensions() {
            const containerWidth = container.clientWidth;
            const containerHeight = containerWidth * 0.8;
            const centerX = containerWidth / 2;
            const centerY = containerHeight / 2;
            const orbitRadiusX = containerWidth / 2;
            const orbitRadiusY = containerHeight / 2;
            const duration = 40;

            gsap.killTweensOf(textItems);

            textItems.forEach((item, index) => {
                const startAngle = (index / totalItems) * Math.PI * 2;

                gsap.timeline({repeat: -1, repeatRefresh: true})
                    .to({}, {
                        duration: duration,
                        ease: "none",
                        onUpdate: function () {
                            const currentAngle = startAngle + gsap.getProperty(this, "time") * (Math.PI * 2 / duration);
                            const x = centerX + orbitRadiusX * Math.cos(currentAngle);
                            const y = centerY + orbitRadiusY * Math.sin(currentAngle);
                            item.style.left = `${x}px`;
                            item.style.top = `${y}px`;
                        }
                    });
            });
        }

        setEllipseDimensions();
        window.addEventListener('resize', setEllipseDimensions);
    }();

    // benefits accord
    !function () {
        const faqs = document.querySelectorAll('.s-faqs__accord-item');

        faqs.forEach(item => {
            const toggle = item.querySelector('.s-faqs__accord-head');
            const content = item.querySelector('.s-faqs__accord-content');

            toggle.addEventListener('click', () => {
                toggleAccord(toggle, content);
            })
        })
    }();

    // areas accord
    !function () {
        const areas = document.querySelectorAll('.s-areas__item');

        areas.forEach(item => {
            const toggle = item.querySelector('.s-areas__toggle');
            const content = item.querySelector('p');

            toggle.addEventListener('click', () => {
                toggleAccord(toggle, content);
            })
        })
    }();

    // Insight Slider
    !function () {
        let slider = document.querySelector('.s-insights__slider');

        if (!slider) return;

        let swiperSection = new Swiper(slider, {
            slidesPerView: 'auto',
            spaceBetween: 68,
            navigation: {
                nextEl: '.s-insights .arrows__arrow--next',
                prevEl: '.s-insights .arrows__arrow--prev',
            },
            breakpoints: {
                1280: {
                    spaceBetween: 168
                }
            }
        });
    }();

    // Anchor scroll
    !function () {
        const anchorsBtns = document.querySelectorAll('.s-article__anchor');

        if (anchorsBtns.length === 0) return;

        const scrollOffset = 150;

        anchorsBtns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();

                const id = btn.getAttribute('href')?.slice(1);
                if (!id) return;

                const target = document.getElementById(id);
                if (target) {
                    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - scrollOffset + 10;

                    window.scrollTo({
                        top: targetPosition,
                        behavior: 'smooth'
                    });
                }
            });
        });

        const onScroll = () => {
            anchorsBtns.forEach((btn) => {
                const id = btn.getAttribute('href')?.slice(1);
                if (!id) return;

                const section = document.getElementById(id);
                if (!section) return;

                const rect = section.getBoundingClientRect();
                const isInView = rect.top < scrollOffset && rect.bottom > 0;

                if (isInView) {
                    document.querySelector('.s-article__anchor.is-current')?.classList.remove('is-current');
                    btn.classList.add('is-current');
                }
            });
        };

        onScroll();

        document.addEventListener('scroll', onScroll);
    }();

    // Article accord
    !function () {
        const open = document.querySelector('.s-article__aside-content h4');
        const content = document.querySelector('.s-article__anchors');

        if (!open || window.innerWidth > 1280) return false;
        open.addEventListener('click', () => {
            toggleAccord(open, content);
        })
    }();

    // Js filter
    !function () {
        const filter = document.querySelector('.s-filter');
        const filterRadios = document.querySelectorAll('.s-filter .s-checkbox input');
        const filterCount = document.querySelector('.s-filter__count span');
        const filterCategory = document.querySelector('.s-filter__category');

        if (filterRadios.length <= 0) return false;

        function getActiveCategory() {
            const activeInput = document.querySelector('.s-filter .s-checkbox input:checked');

            filterCount.innerHTML = activeInput.getAttribute('data-filter-count');
            filterCategory.innerHTML = activeInput.getAttribute('data-filter-category');
        }

        const caseContainer = document.querySelector(".s-case__container");
        const caseGhost = document.querySelector(".s-case__grid--ghost");

        let jsonData = [];

        // Fetch data from local JSON file
        fetch("json/data.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                jsonData = data;
                initialize();
            })
            .catch((error) => console.error("Error fetching JSON:", error));

        function renderItems(items) {
            if (!filter.classList.contains('s-filter--with-json')) return false;

            setGhostLoading();
            caseContainer.innerHTML = "";
            items.forEach((item, index) => {
                const itemHTML = `
                <div class="s-case__item" style="${Object.entries(item.style)
                    .map(([key, value]) => `${key}:${value};`)
                    .join("")}">
                    <div class="s-case__item-poster">
                        <div class="s-case__item-preview">
                            ${
                    item.mediaType === "video"
                        ? `<video src="${item.mediaSrc}" playsinline poster="${item.poster}" autoplay loop muted></video>`
                        : `<img src="${item.poster}" alt="${item.title}"/>`
                }
                        </div>
                        <div class="s-case__item-hover is-hide delay-group">
                            <div class="s-case__item-hover-content in-view-detect-tag">
                                <h3>${item.description}</h3>
                                <a href="${item.url}" class="btn btn--transparent">
                                    <div class="btn__body">
                                        <div class="btn__arrow">
                                            <svg>
                                                <use xlink:href="#btn-arrow-ico"></use>
                                            </svg>
                                        </div>
                                        <div class="btn__text" data-text>View project</div>
                                        <div class="btn__dot"></div>
                                    </div>
                                </a>
                            </div>
                            <div class="s-case__item-hover-back">
                                <svg viewBox="0 0 507 362" xmlns="http://www.w3.org/2000/svg" filter="url(#case-filter-${index}-0)">
                                    <defs>
                                        <filter id="case-filter-${index}-0" x="0" y="-98" width="507" height="460" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3002_5453"/>
                                        </filter>
                                        <linearGradient id="case-fill-${index}-0" x1="477" y1="-68" x2="29.114" y2="331.006" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="var(--hover-gradient-start)"/>
                                            <stop offset="1" stop-color="var(--hover-gradient-end)" stop-opacity="0"/>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#case-fill-${index}-0)" d="M211.433 328.359C303.213 348.197 391.071 283.921 437.745 209.726C484.419 135.53 489.91 51.8129 451.865 -2.94072C413.427 -57.2976 331.453 -81.8971 236.927 -60.075C142.794 -37.8561 35.7176 30.7844 30.2265 117.279C24.7354 204.171 120.045 308.124 211.433 328.359Z"/>
                                </svg>
                                <svg viewBox="0 0 200 220" xmlns="http://www.w3.org/2000/svg" filter="url(#case-filter-${index}-1)">
                                    <defs>
                                        <filter id="case-filter-${index}-1" x="0" y="0" width="200" height="220" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3002_5452"/>
                                        </filter>
                                        <linearGradient id="case-fill-${index}-1" x1="30" y1="190" x2="174.37" y2="34.0451" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="var(--hover-gradient-start)"/>
                                            <stop offset="1" stop-color="var(--hover-gradient-end)" stop-opacity="0"/>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#case-fill-${index}-1)" d="M34.0228 87.3163C24.3389 118.96 32.349 156.958 55.7817 175.893C79.2144 194.829 118.07 194.701 142.22 175.512C166.489 156.322 175.934 117.816 166.25 86.2997C156.566 54.656 127.634 29.7478 99.4192 30.0019C71.2043 30.2561 43.7067 55.6727 34.0228 87.3163Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="s-case__item-desc in-view-detect-tag">
                        <h4><a href="${item.url}">${item.title}</a></h4>
                        <h6>${item.subtitle}</h6>
                    </div>
                </div>
            `;
                caseContainer.insertAdjacentHTML("beforeend", itemHTML);
            });
            initInViewDetect(1300);
            setCasesHover();
            const newShuffleBtn = caseContainer.querySelectorAll('.btn');
            initShuffle(newShuffleBtn);
            timer = setTimeout(() => {
                removeGhostLoading();
            }, 1200)
        }

        let timer = null

        function setGhostLoading() {
            clearTimeout(timer);
            caseContainer.classList.add('is-hide');
            caseGhost.classList.remove('is-hide');
        }

        function removeGhostLoading() {
            caseContainer.classList.remove('is-hide');
            caseGhost.classList.add('is-hide');
        }

        function initialize() {
            getActiveCategory();

            const all = [];
            jsonData.forEach(items => {
                items.items.forEach(unit => all.push(unit));
            });

            filterRadios.forEach((radio) => {
                radio.addEventListener("change", (event) => {
                    const category = event.target.getAttribute("data-filter-category");
                    const filteredCategory = jsonData.find((data) => data.category === category);
                    if (filteredCategory) {
                        renderItems(filteredCategory.items);
                    } else {
                        renderItems(all);
                    }

                    getActiveCategory();
                });
            });

            setTimeout(() => {
                const defaultCategory = document.querySelector('.s-filter .s-checkbox input:checked').getAttribute("data-filter-category");
                const initialCategory = jsonData.find((data) => data.category === defaultCategory);
                if (initialCategory) {
                    renderItems(initialCategory.items);
                } else {
                    renderItems(all);
                }
            }, 500)
        }
    }();

    // Case slider
    !(function () {
        let slider = document.querySelector('.s-case__slider');

        if (!slider) return;

        let swiperSection = new Swiper(slider, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            navigation: {
                nextEl: '.s-case--slider .arrows__arrow--next',
                prevEl: '.s-case--slider .arrows__arrow--prev',
            },
            breakpoints: {
                1280: {
                    spaceBetween: 52,
                }
            }
        });
    })();

    // Single testimonials slider
    !(function () {
        let slider = document.querySelector('.s-single-testimonials__slider');

        if (!slider) return;

        let swiperSection = new Swiper(slider, {
            slidesPerView: 1,
            spaceBetween: 48,
            navigation: {
                nextEl: '.s-single-testimonials__slider .arrows__arrow--next',
                prevEl: '.s-single-testimonials__slider .arrows__arrow--prev',
            },
            scrollbar: {
                el: '.s-single-testimonials__slider .swiper-scrollbar',
                draggable: true,
            },
            breakpoints: {
                768: {
                    spaceBetween: 200,
                }
            }
        });
    })();

    // collaboration accord
    !function () {
        const collaborations = document.querySelectorAll('.s-collaboration--mob-accord .s-collaboration__item');

        if (collaborations.length <= 0) return false;

        collaborations.forEach(item => {
            const toggle = item.querySelector('h3');
            const content = item.querySelector('p');

            toggle.addEventListener('click', () => {
                if (window.innerWidth < 768) {
                    toggleAccord(toggle, content);
                }
            })
        })
    }();

    // Intro more btn
    !function () {
        const introMoreBtn = document.querySelector('.s-intro__more');

        if (!introMoreBtn) return false;

        introMoreBtn.addEventListener('click', () => {
            const content = introMoreBtn.previousElementSibling;
            content.classList.toggle('is-active');

            const isActive = content.classList.contains('is-active');

            introMoreBtn.innerHTML = isActive ? 'read less' : 'read more';

            content.style.maxHeight = isActive ? content.scrollHeight + 'px' : '100px';
        })
    }();
});

function toggleBodyLock(action) {
    const method = action === 'lock' ? 'addClass' : 'removeClass';
    $('html, body')[method]('lock');

    if (lenisScroll) {
        action === 'lock' ? lenisScroll.stop() : lenisScroll.start();
    }
}

function bodyLock() {
    toggleBodyLock('lock');
}

function bodyUnlock() {
    toggleBodyLock('unlock');
}

function bodyLockToggle() {
    const isLocked = document.body.classList.contains('lock');
    toggleBodyLock(isLocked ? 'unlock' : 'lock');
}

function toggleAccord(toggle, content, activeClass = 'is-opened') {
    if (toggle.classList.contains(activeClass)) {
        toggle.classList.remove(activeClass);
        content.style.maxHeight = 0;
    } else {
        toggle.classList.add(activeClass);
        content.style.maxHeight = content.scrollHeight + 'px';
    }
}